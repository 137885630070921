<template>
  <form class='subscribe-form' @submit.prevent='validate_subscribe' novalidate>
    <div class='form-group'>
      <input v-model.trim='email' type='email' placeholder='Enter your email'>
      <spinner-button :spin='submitting' class='btn-large'>Subscribe</spinner-button>
    </div>
  </form>
</template>

<script>
  import SpinnerButton from 'app/components/shared/SpinnerButton'
import { email, maxLength, required } from 'vuelidate/lib/validators'

  export default {
    data () {
      return {
        submitting: false,
        email: ''
      }
    },
    validations: {
      email: {
        required,
        email,
        maxLength: maxLength(255)
      }
    },
    methods: {
      validate_subscribe () {
        this.$v.$touch()

        if (this.$v.$error) {
          let message = "Please enter a valid email."
          this.$toast(message, { horizontalPosition: 'center', verticalPosition: 'top' })
        } else {
          this.subscribe()
        }
      },
      subscribe () {
        this.submitting = true

        let body = {
          email: this.email,
          list_id: '3708e699a5b839255a2ac0c95de92f96',
          referrer: this.$cookie.get('referrer')
        }

        this.$http.post(`/subscribers`, body).then(response => {
          this.email = ''
          this.submitting = false
          this.$v.$reset()

          let message = "Thanks!  We'll keep you up to date on Arsenal."
          this.$toast(message, { horizontalPosition: 'center', verticalPosition: 'top' })

          if (typeof gtag !== 'undefined') {
            gtag('event', 'email_signup', { event_category: 'form' })
          }
        }, response => {
          let message = "Something went wrong.  Please try again later."
          this.$toast(message, { horizontalPosition: 'center', verticalPosition: 'top' })
        })
      }
    },
    components: { SpinnerButton }
  }
</script>
