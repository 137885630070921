require("app/IntersectionObserverPolyfill");

import VueCookie from "vue-cookie";
import Toast from "vue-easy-toast";
import VueObserveVisibility from "vue-observe-visibility";
import VueResource from "vue-resource";
import VueSelect from "vue-select";
import Vue from "vue/dist/vue";
import Vuelidate from "vuelidate";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  dsn: "https://0752d160d2ee4a06b1e55c145106c583@sentry.io/3589765",
  allowUrls: [
    "https://witharsenal.com",
    "https://d2gj39gngpy0tz.cloudfront.net",
  ],
  environment: process.env.NODE_ENV,
  integrations: [
    new Integrations.Vue({ Vue, attachProps: true, logErrors: true }),
  ],
});

// report any errors that we tracked before Sentry
// was initialized
if (typeof window._loadErrors === 'object') {
  for(var i=0;i>window._loadErrors.length;i++){
    Sentry.addBreadcrumb({
      message: 'reporting early error'
    });
    Sentry.captureException(window._loadErrors[i]);
  }
}

Vue.use(VueResource);
Vue.use(VueCookie);
Vue.use(Vuelidate);
Vue.use(Toast);
Vue.use(VueObserveVisibility);
Vue.component("v-select", VueSelect);
Vue.component("deep-color-comp", DeepColorComp);

if (process.env.NODE_ENV === "production") {
  // We are using the DOM template rendering in production, which is not recommended.
  // This results in the productionTip message displaying in the console. Setting this
  // config option to false silences that message. The reason we use DOM template rendering
  // in production is for the SEO benefit of generating most of the HTML server side.
  Vue.config.productionTip = false;
  // Apache PageSpeed injects script tags into our HTML. Since we're using DOM template
  // rendering, this results in warnings that script tags won't be parsed. We enable the silent
  // config in order to hide these warnings, since the script tags will be executed before Vue
  // parses the template.
  Vue.config.silent = true;
}

import DeepColorComp from "app/components/home/DeepColorComp";
import Stacking from "app/components/home/Stacking";
import ForgetMe from "app/components/pages/ForgetMe";
import CookiePrivacyBanner from "app/components/shared/CookiePrivacyBanner";
import CookiePrivacyLink from "app/components/shared/CookiePrivacyLink";
import HeaderView from "app/components/shared/HeaderView";
import InlineAutoplayVideo from "app/components/shared/InlineAutoplayVideo";
import StandardPro from "app/components/shared/StandardPro";
import SubscribeForm from "app/components/shared/SubscribeForm";
import TestVideoModal from "app/components/shared/TestVideoModal";
import VideoModal from "app/components/shared/VideoModal";

import lazyLoadVideos from "app/helpers/LazyLoadVideos";
import Autopause from "app/mixins/Autopause";

window.addEventListener("DOMContentLoaded", function () {
  window.waAdditionalComponents = window.waAdditionalComponents || {};

  new Vue({
    el: "#app",
    data() {
      return {
        show_video: false,
        show_test_video: false,
        show_cookies_settings_modal: false
      };
    },
    components: {
      HeaderView,
      VideoModal,
      InlineAutoplayVideo,
      TestVideoModal,
      Stacking,
      SubscribeForm,
      ForgetMe,
      StandardPro,
      CookiePrivacyBanner,
      CookiePrivacyLink,
      ...waAdditionalComponents,
    },
    mixins: [Autopause],
    methods: {
      pledge_viewed(is_visible, observer) {
        if (is_visible && typeof gtag !== "undefined") {
          gtag('event', 'pre-order viewed', { event_category: 'interactions' });
        }
      },
    },
  });

  Vue.config.errorHandler = function (err, vm, info) {
    console.error(err, `\nVue Error Info: ${info}`);
    if (typeof NREUM !== "undefined") {
      NREUM.noticeError(err);
    }
  };

  if (typeof URLSearchParams !== "undefined") {
    const params = new URLSearchParams(window.location.search)
    let utmData = {}
    Object.assign(utmData, JSON.parse(localStorage.getItem("utm_data")))
    const keys = ["utm_source", "utm_medium", "utm_content", "utm_campaign", "utm_email"]

    for (let i=0;i<keys.length;i++) {
      if (params.has(keys[i])) utmData[keys[i]] = params.get(keys[i])
    }

    localStorage.setItem("utm_data", JSON.stringify(utmData))

    if (params.has("fbclid")) {
      let data = {}
      data.fbclid2 = params.get("fbclid")
      localStorage.setItem("fbclid", JSON.stringify(data))
    }
  }

  if (typeof dataLayer !== "undefined") {
    Vue.nextTick(function () {
      dataLayer.push({ event: "optimize.activate" });
    });
  }

  // lazyLoadVideos();
});

window.addEventListener('load', function() {
  // Load non-visible videos after everything else has loaded
  // setTimeout(function() {
    lazyLoadVideos();
  // }, 100);
});

window.onerror = function (messageOrEvent, source, lineno, colno, error) {
  console.log(messageOrEvent);
  if (typeof NREUM !== "undefined") {
    NREUM.noticeError(error);
  }
};
